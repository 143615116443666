@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

* {
  touch-action: auto;
}

html, body, #root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  user-select: none;
}

@supports (background: -webkit-named-image(i)) {
  html, body, #root {
    height: -webkit-fill-available
  }
}

html,body{
  overflow: hidden !important;
}

textarea:focus, input:focus {
  outline: none;
}